import {createRouter, createWebHashHistory} from 'vue-router';

// 定义路由规则，url对应组件
const routes = [
    {
        path: '/',
        name: 'app',
        redirect: '/tradingview'
    },
    {
        path: "/tradingview",
        name: "tradingview",
        component: () => import("../views/Tradingview/index.vue"),
    },
    {
        path: "/quoted-price",
        name: "quote-price",
        component: () => import("../views/QuotePrice/index.vue"),
    }
]

// 创建路由
const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.onError((error) => {
    console.log({error});
});

export default router
