import { createApp } from 'vue'
import App from './App.vue';
import "@/style/reset.scss";
import "@/style/common.scss";
import 'element-plus/dist/index.css'
import router from "./router/index.js";
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'



createApp(App).use(router).mount('#app')
