const postMessage = (params = {}) => {
    if(!window?.chrome?.webview?.postMessage){
        console.error("自定义LOG：暂不支持此方式传输数据！");
        return false;
    }
    console.log('H5 TO PC',params.method)
    window?.chrome?.webview?.postMessage(encodeURI(JSON.stringify(params)))
}
export {
    postMessage
}
