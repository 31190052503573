<template>
  <template v-if="PCHeaders">
    <router-view></router-view>
  </template>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { postMessage } from '@/utils/webview'
const PCHeaders = ref('');
onMounted(()=>{
  postMessage({
    method: "getUserInfo"
  });
  let data = localStorage.getItem('PCHeaders');
  if(data){
    data = JSON.parse(data);
    PCHeaders.value = data;
  }
  window.setUserInfo = (info) => {
    console.log({info})
    PCHeaders.value = info;
    localStorage.setItem("PCHeaders", JSON.stringify(info));
  };
})
// setUserInfo({"gf-ip":"172.16.61.12","gf-deviceNo":"A2AD09FF-645F-4453-B611-6BD664163E3D","accept-language":"zh-CN","gf-version":"Windows 11 Pro","gf-device":"DESKTOP-34QMNNT","gf-clientVersion":"1.1.74","gf-clientTypes":"PC","platform":"window","gf-network":"ethernet","gf-system":"3","Authorization":"Bearer eyJpdiI6Imxlb3dSWTZZWUNYMU5ZXC9ZUnoyeUJ3PT0iLCJ2YWx1ZSI6IjlQUityWWNLdHFXYlJzS1UwVzJoR0MxUFdGaDdYc1wvaUNSR1YzYjE2YjlGTnI3UCtlXC9laUdxZnJyN2EycUlSTCIsIm1hYyI6ImNlMDYyZTIwZWEwMmU1NmFjY2Q5MTI4NWY0OGYxOTc1N2M1NDNmNzFlNzUwZWVhNWM3MzIyNzdkZjc3ZGRjMDcifQ=="})

</script>

<style>
</style>
